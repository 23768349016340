
import { defineComponent } from "vue";

export default defineComponent({
  name: "SelfTest",
  mounted() {
    setTimeout(() => {
      window.location.href = "/kakao-i-connect-live-self-test/";
    }, 3000);
  }
});
